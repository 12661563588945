<template style="height: 100%;position: relative;">
  <div>
    <div class="filter_wrap" v-if="initCate">
      <div class="container_wrap">
        <div class="tag-group" v-if="!category_lv1_id && tagsItem1.length > 2">
          <span class="tag-group__title">一级分类</span>
          <div class="tags_content">
            <el-tag :class="{'active': item.id === activeItemId}" v-for="(item, index) in tagsItem1"
                    @click="clickCate1(index)" :key="item.id">
              {{ item.name }}
            </el-tag>
          </div>
        </div>
        <div class="tag-group">
          <span class="tag-group__title" v-if="category_lv1_id || tagsItem1.length <= 2">分类</span>
          <span class="tag-group__title" v-else>二级分类</span>
          <div class="tags_content">
            <span v-for="(item, index) in tagsItem2">
              <el-tag :class="{'active': item.active === true}" v-if="item.id == 0 || item.parent_id == activeItemId || activeItemId == 0"
                      @click="clickCate2(index)" :key="item.id">
                {{ item.name }}
              </el-tag>
            </span>
          </div>
        </div>
        <div class="tag-group" v-if="hasAuth">
          <span class="tag-group__title">认证</span>
          <div class="tags_content">
            <el-tag :class="{'active': activeAuth === 0}" @click="filterAuth(0)" :key="0">全部</el-tag>
            <el-tag :class="{'active': activeAuth === 1}" @click="filterAuth(1)" :key="1">阿里云</el-tag>
            <el-tag :class="{'active': activeAuth === 2}" @click="filterAuth(2)" :key="2">腾讯云</el-tag>
          </div>
        </div>
      </div>
    </div>
    <div class="container_wrap" v-loading="loading" style="min-height: 500px;">
      <el-row :gutter="30">
        <el-col :span="6" v-for="(item, index) in courseItem" :key="index">
          <el-card :body-style="{ padding: '0px' }" shadow="hover" class="card_item_common">
            <div class="cover" @click="goPathToNewPage('/course/detail/'  + item.uuid)">
              <img :src="item.cover_image" class="image">
              <span class="hot" v-if="item.cover_image_label && item.cover_image_label != 'null'">{{ item.cover_image_label }}</span>
              <span class="tag" v-if="item.type">{{ item.type }}</span>
            </div>
            <div class="content">
              <div class="title text_ellipsis" @click="goPathToNewPage('/course/detail/' + item.uuid)">{{ item.name }}</div>
              <div class="price_wrap">
                <span class="info" style="float:left">
                  <span v-if="item.position"> {{ item.position }} &nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span v-if="item.start_at"> {{ item.start_at }}</span>
                    <span v-else-if="item.duration && item.total_seconds > 0">{{ item.duration }}</span>
                  <span v-if="item.experiment_len">{{ item.experiment_len }}</span>
                </span>
              </div>
            </div>
            <div class="bottom">
              <span>
                <i class="iconfont icon-icon_huaban1"></i>{{ item.view }}
              </span>
              <span>
                <i class="iconfont icon-icon-02"></i>{{item.like}}
              </span>
              <span>
                <i class="iconfont icon-icon-03"></i>{{item.favor}}
              </span>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <Pagination :pager="pager" v-if="pager.total > 0" ref="pagination" @getPager="getPager" :page-sizes="[12,24,48,72,96]"></Pagination>
    </div>
  </div>
</template>

<script>
import { goPathToNewPage } from 'utils/common'
import { listCate } from "api/course/cate/list"
import { listUserCur } from "api/course/course/list"
import { get_favor_like } from "api/course/course/create"
import Pagination from "components/common/Pagination"
export default {
  name: 'CurList',
  props: {
    initCate: {
      type: Boolean,
      default: true
    },
    initAuth: {
      type: Boolean,
      default: true
    },
    category_lv1_id: {
      type: String,
      default: '',
    },
    category_lv2_ids: {
      type: String,
      default: '',
    },
    curFilter: {
      type: Object,
      default: {}
    },
    filters: {
      type: Object,
      default: {}
    },
    zone_uuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      firstInit: true,
      activeItemId: 0,
      tagsItem1: [{
        id: 0,
        name: '全部',
        active: true,
      }],
      tagsItem2: [{
        id: 0,
        name: '全部',
        active: true
      }],
      courseItem: [],
      pager: {},
      hasAuth: false,
      activeAuth: 0,
      loading: true,
    }
  },
  components: {
    Pagination
  },
  mounted(){
    this.pager = {
      pageSize: 12,
      currentPage: 1,
      total: 0
    }
  },
  methods: {
    goPathToNewPage,
    getFavorLike(courseItem){
      courseItem.forEach(item => {
        get_favor_like(item.uuid).then(res => {
          item.like = res.like.num
          item.favor = res.favor.num
          item.view = res.view_num
        })
      })
    },
    getCurList() {
      const that = this
      let filters = {state: 3, need_info: true, need_type: true}
      filters = Object.assign(filters, that.filters)
      if (that.activeAuth != 0) {
        filters['auth_type'] = that.activeAuth
      }
      if(Object.keys(that.curFilter).length > 0){ // 查询课程集合
        if(that.curFilter.chapter_include == 1){  // 全为
          filters['type'] = that.curFilter.chapter_type[0]
        }
        else if(that.curFilter.chapter_include == 2){ //含有
          let types = []
          that.curFilter.chapter_type.forEach(item => {
            types.push(item[0])
          })
          filters['include_type'] = types.join(',')
        }
        if(that.curFilter.people_num == 1){
          filters['people_num'] = 0
        }
        else if(that.curFilter.people_num == 2){
          filters['people_num'] = '>0'
        }
        if(that.curFilter.price == 1){
          filters['final_price'] = 0
        }
        else if(that.curFilter.price == 2){
          filters['final_price'] = '>0'
        }
      }
      else {  // 查询课程分类
        if(that.category_lv2_ids != ''){
          filters['category_lv2_ids'] = that.category_lv2_ids.join(',')
        }
        else if(that.category_lv1_id != '' && that.category_lv1_id != 0){
          filters['category_lv1_id'] = that.category_lv1_id
        }
      }

      //按照分类查询
      let cates = []
      that.tagsItem2.forEach((item, index) => {
        if(item.active){
          cates.push(item.id)
        }
      })
      if (cates.indexOf(0) == -1){  // 二级分类不是全部
        filters['category_lv2_ids'] = cates.join(',')
      }
      else if(that.activeItemId != 0){
        filters['category_lv1_id'] = that.activeItemId
      }

      if (that.zone_uuid != null){
        filters['zone_uuid'] = that.zone_uuid;
      }

      filters['offset'] = (that.pager.currentPage - 1) * that.pager.pageSize
      filters['limit'] = that.pager.pageSize
      filters['needCancel'] = true
      listUserCur(filters).then(res => {
        that.loading = false
        console.log("--------------------------lc")
        console.log(res.data)
        that.courseItem = res.data
        that.getFavorLike(that.courseItem)
        that.pager.total = res.total
      })
    },
    getCateList() {
      const that = this
      let filters = {'need_count': false, 'in_cur': true}

      if (that.zone_uuid != null) {
        filters['zone_uuid'] = that.zone_uuid;
      }

      if(that.category_lv1_id) {
        filters['category_lv1_id'] = this.category_lv1_id
        filters['level'] = 2
      }
      else if(that.category_lv2_ids){
        filters['category_lv2_ids'] = this.category_lv2_ids.join(',')
      }
      if(that.filters.final_price != undefined){
        filters['final_price'] = that.filters.final_price
      }
      if(that.filters.query != undefined) {
        filters['cur_query'] = that.filters.query
      }
      listCate(filters).then(res => {
        that.tagsItem1 = [{
          id: 0,
          name: '全部',
          active: true,
        }]
        that.tagsItem2 = [{
          id: 0,
          name: '全部',
          active: true
        }]
        for(var i=0; i<res.data.length; i++){
          let cate = {
            id: res.data[i].id,
            name: res.data[i].name,
            active: false
          }
          if(res.data[i].level == 1){
            that.tagsItem1.push(cate)
          }
          else {
            cate['parent_id'] = res.data[i].category_lv1_id
            that.tagsItem2.push(cate)
          }
        }
      })
    },
    clickCate1(index) {
      let item = this.tagsItem1[index]
      if(item.id != this.activeItemId){
        this.activeItemId = item.id
        for(let i=1; i<this.tagsItem2.length; i++){
          this.tagsItem2[i].active = false
        }
        this.tagsItem2[0].active = true
      }
      this.pager.currentPage = 1
      this.getCurList()
    },
    clickCate2(index) {
      for(let i=0; i<this.tagsItem2.length; i++){
        this.tagsItem2[i].active = false
      }
      this.tagsItem2[index].active = true
      this.pager.currentPage = 1
      this.getCurList()
    },
    filterAuth(id) {
      this.activeAuth = id
      this.pager.currentPage = 1
      this.getCurList()
    },
    goPath(path) {
      this.$router.push(path)
    },
    getPager(pager) { //获取分页变化后数据
      this.pager = pager
      this.getCurList()
    },
    // updateFilters(val) {
    //   this.filters = Object.assign({}, val)
    //   this.getCurList()
    //   this.getCateList()
    // }
  },
  watch: {
    filters (val) {
      this.getCurList()
      this.getCateList()
    },
    zone_uuid(val){
      console.log('watch->zone_uuid:', this.zone_uuid)
      this.getCurList()
      if (this.initCate) {
        this.getCateList()
      }
    }
  }
}
</script>

<style lang="scss">

</style>
