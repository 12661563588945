/**
 * 用户专区相关 API
 */
import { fetch, post, postForm } from 'api/base/axios'
import { js_time_to_str } from 'utils/timeutils'


const URL = {
  detailUrl: '/zone/detail/',
};

export function detailZone (zone_domain) {
  return post(URL.detailUrl, {
    'zone_domain': zone_domain,
    'needCancel': true
  })
}



