<template>
  <div>
    <div class="title_streamer">
      <div class="container_wrap clearfix">
        <div class="pull-left">
          <img :src="zone_instance.logo_image" />
          <span>{{ zone_instance.name }}</span>
        </div>
        <div class="pull-right">
           <el-button type="text" size="small" v-if="zone_user" @click="diy_experiment()">进入开放实验室</el-button>
           <el-button size="small" v-if="!zone_user" @click="checkUserLogin()">加入</el-button>
           <el-button size="small" v-if="zone_user" disabled>已加入</el-button>
        </div>
      </div>
    </div>
    <CurList :zone_uuid="zone_instance.uuid"></CurList>

    <!-- 加入企业 -->
    <el-dialog
      :title="joinModal.title"
      top="40px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-model="joinModal.visible"
      width="600px"
      @closed="closeModal('joinForm')">
      <div>
        <el-form :model="joinForm" :rules="rules" ref="joinForm" label-width="100px">
          <h4 class="modal_title">个人信息</h4>
          <el-form-item label="姓名" prop="realname">
            <el-input v-model.trim="joinForm.realname " />
          </el-form-item>
          <el-form-item label="职业" prop="job">
            <el-radio-group v-model.trim="joinForm.job">
              <el-radio label="office">职员</el-radio>
              <el-radio label="student">学生</el-radio>
              <el-radio label="teacher">教师</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="单位/学校" prop="work_place">
            <el-input v-model.trim="joinForm.work_place" />
          </el-form-item>
          <el-form-item label="岗位/专业" prop="job_name">
            <el-input v-model.trim="joinForm.job_name" />
          </el-form-item>
          <h4 class="modal_title">授权信息</h4>
          <el-form-item label="访问码" prop="zone_user_code">
            <el-input v-model.trim="joinForm.zone_user_code" />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="joinModal.visible = false">取 消</el-button>
            <el-button type="primary" @click="submitForm('joinForm')">确 定</el-button>
          </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import CurList from "components/zone/CurList";
import { detailZone } from 'api/operation/zone/user'
import { GetUserState } from 'api/user/auth/login'
import { showMessage } from 'api/base/message'
import { checkZoneUser,getUserInfo } from 'api/experiment/list'
import { updateUserLoginState } from 'api/user/auth/login'
import { getUrlParam } from 'utils/common'

export default {
  components: {
    CurList
  },
  async created() {
    if (this.$route.params.zone_domain != undefined) {
      this.zone_domain = this.$route.params.zone_domain;
      await this.getZoneDetail();
    }
  },
  data() {
    return {
      preview_mode: false,
      zone_domain: null,
      zone_uuid : null,
      zone_instance: {},
      zone_user:false,
      joinModal: {
        title: '加入企业',
        visible: false,
      },
      joinForm: {
        realname: '',
        job: '',
        work_place: '',//单位
        job_name: '',//岗位
        zone_user_code: '',
        zone_id:'',
      },
      rules: {
        work_place: [
          { required: true, message: '必填项', trigger: 'blur' },
          { min: 1, max: 50, message: '字符长度限制在1到50个字符以内', trigger: 'blur' }
        ],
        realname: [
          { required: true, message: '必填项', trigger: 'blur' },
          { min: 1, max: 50, message: '字符长度限制在1到50个字符以内', trigger: 'blur' }
        ],
        job: [
          { required: true, message: '请选择职位', trigger: 'change'}
        ],
        job_name: [
          { required: true, message: '必填项', trigger: 'blur' },
          { min: 1, max: 50, message: '字符长度限制在1到50个字符以内', trigger: 'blur' }
        ],
        zone_user_code: [
          { required: true, message: '请填写访问码', trigger: 'blur' }
        ],
      }
    }
  },
  mounted() {
    this.checkzoneuser()
  },
  methods: {

    async getZoneDetail(){

      const that = this;

      await detailZone(this.zone_domain).then(result=>{

        if (result.succeed) {

          let is_preview = getUrlParam('preview') ;
          if(is_preview === 'true'){
            this.preview_mode = true;
          }

          if (this.preview_mode) {
            this.zone_instance = result.instance;
            this.zone_uuid = result.instance.uuid;
            console.log(this.zone_instance);
          } else {

            if (result.is_admin || (result.instance.is_enabled && result.instance.service_state_const === 2)) {
              this.zone_instance = result.instance;
              this.zone_uuid = result.instance.uuid;
              console.log(this.zone_instance);
            } else {
              window.location.href = '/404';
            }

          }

        } else {

          switch (result.error_code) {
            case 0x02: {
              window.location.href = '/404';
            } break;
            default: {
              that.$alert(`${result.error_msg}`, '提示', {
                confirmButtonText: '确定',
              });
            } break;
          }

        }

      }).catch(err=>{
        if (!err.__CANCEL__) {
          that.$alert(`${err}`, '提示', {
            confirmButtonText: '确定',
          });
        }
      })
    },
    submitForm(formName) {
      const that = this;
      this.$refs[formName].validate(valid => {
        if(valid) {
          getUserInfo(this.joinForm).then((res) => {
            if(res.succeed){
              this.zone_user = true
              console.log('保存成功')
            } else {
              if(res.error_code == 1){
                  that.$emit('update:loginVisible', true)
              }else{
                  showMessage(res.error_msg)
              }
            }
          })
          this.joinModal.visible = false
        }
      })
    },
    closeModal(formName) {
      Object.assign(this.joinForm, this.$options.data().joinForm)
      this.$refs[formName].clearValidate()
    },
    checkzoneuser(){
      checkZoneUser({'domain':this.zone_domain}).then((res) => {
          if (res.code == 1){
            this.zone_user = true
          }else{
            this.zone_user = false
          }
      })
    },
    getuserstate(){
      GetUserState().then(res=>{
        if(res){
           this.joinForm.realname = res.realname;
           this.joinForm.job = res.job;
           this.joinForm.work_place = res.work_place;
           this.joinForm.job_name = res.job_name;
           this.joinForm.zone_id = this.zone_instance.id;
        }
      })
    },
    checkUserLogin() {
        let that = this;
        updateUserLoginState().then((res) => {
          if (res.hasOwnProperty("error") && res.error === 'Not Login State') {
            that.$emit('update:loginVisible', true)
          }else{
            this.getuserstate()
            that.joinModal.visible = true
          }
        }).catch(error => {

        });
    },
    diy_experiment(){
      this.$router.push(`/diy/experiment`);
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 20px;
  font-size: 18px;
  .span-icon {
    position: relative;
    top: 2px;
    margin-right: 7px;
    width: 4px;
    height: 18px;
    background: #009DD9;
    display: inline-block;
  }
}
.title_streamer {
  background:#2c71bf;
  padding-top: 20px;
  padding-bottom:20px;
  margin-left:-20px;
  margin-right:-20px;
  margin-bottom:40px;
  .pull-left {
    img, span {
      vertical-align: middle;
    }
    img {
      margin-right:20px;
      height: 38px;
    }
    span {
      font-size:24px;
      color:#fff;
    }
  }
  .pull-right {
    .el-button--text {
      margin-right:20px;
      color:#fff;
    }
  }
}

.icon {
   width: 1em; height: 1em;
   vertical-align: -0.15em;
   fill: currentColor;
   overflow: hidden;
}
</style>
